exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-histoire-bgatakanen-index-tsx": () => import("./../../../src/pages/histoire-bgatakanen/index.tsx" /* webpackChunkName: "component---src-pages-histoire-bgatakanen-index-tsx" */),
  "component---src-pages-histoire-bgatakanen-inquiry-tsx": () => import("./../../../src/pages/histoire-bgatakanen/inquiry.tsx" /* webpackChunkName: "component---src-pages-histoire-bgatakanen-inquiry-tsx" */),
  "component---src-pages-histoire-bgatakanen-inquirycomplete-tsx": () => import("./../../../src/pages/histoire-bgatakanen/inquirycomplete.tsx" /* webpackChunkName: "component---src-pages-histoire-bgatakanen-inquirycomplete-tsx" */),
  "component---src-pages-histoire-bgatakanen-inquiryconf-tsx": () => import("./../../../src/pages/histoire-bgatakanen/inquiryconf.tsx" /* webpackChunkName: "component---src-pages-histoire-bgatakanen-inquiryconf-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-rule-tsx": () => import("./../../../src/pages/rule.tsx" /* webpackChunkName: "component---src-pages-rule-tsx" */),
  "component---src-pages-verybest-bgatakanen-index-tsx": () => import("./../../../src/pages/verybest-bgatakanen/index.tsx" /* webpackChunkName: "component---src-pages-verybest-bgatakanen-index-tsx" */),
  "component---src-pages-verybest-bgatakanen-inquiry-tsx": () => import("./../../../src/pages/verybest-bgatakanen/inquiry.tsx" /* webpackChunkName: "component---src-pages-verybest-bgatakanen-inquiry-tsx" */),
  "component---src-pages-verybest-bgatakanen-inquirycomplete-tsx": () => import("./../../../src/pages/verybest-bgatakanen/inquirycomplete.tsx" /* webpackChunkName: "component---src-pages-verybest-bgatakanen-inquirycomplete-tsx" */),
  "component---src-pages-verybest-bgatakanen-inquiryconf-tsx": () => import("./../../../src/pages/verybest-bgatakanen/inquiryconf.tsx" /* webpackChunkName: "component---src-pages-verybest-bgatakanen-inquiryconf-tsx" */),
  "component---src-templates-telconversion-tsx": () => import("./../../../src/templates/telconversion.tsx" /* webpackChunkName: "component---src-templates-telconversion-tsx" */)
}

